<template>
  <div class="courseSelection">
    <div class="filtrate" v-if="pageType == 1">
      <div class="filtrate-content">
        <div class="filtrate-list classes">
          <div class="filtrate-title">课程方向：</div>
          <div class="option-list clearfix">
            <span
              class="filtrate-option"
              :class="categorySelect === 0 ? 'active' : ''"
              @click="switchOne(0)"
              >不限</span
            >
            <span
              class="filtrate-option"
              v-for="item in category"
              :key="item.id"
              :class="Number(categorySelect.id) == item.id ? 'active' : '11'"
              @click="switchOne(item)"
              >{{ item.name }}</span
            >
          </div>
        </div>
        <div class="filtrate-list">
          <div class="filtrate-title clearfix">领域：</div>
          <div class="option-list">
            <span
              class="filtrate-option"
              :class="category_id === 0 ? 'active' : ''"
              @click="categoryListBtn(0)"
              >不限</span
            >
            <span
              class="filtrate-option"
              v-for="subItem in categorySelect.child"
              :key="subItem.id"
              :class="category_id == subItem.id ? 'active' : ''"
              @click="categoryListBtn(subItem.id)"
              >{{ subItem.name }}</span
            >
          </div>
        </div>
        <div class="filtrate-list yyzBox">
          <div class="filtrate-title clearfix">是否支持元宇宙跨空间培训: <a href="https://npx.kskstudy.com/activity/detail?id=32" target="_blank" rel="noopener noreferrer">
                 <img src="@/assets/tip_icon.png" alt="">
               </a> </div>
          <div class="option-list">
            
            <span
              class="filtrate-option"
              :class="yyzIndex == '1' ? 'active' : ''"
              @click="changYyzValue('1')"
              >是</span
            >
            <span
              class="filtrate-option"
              :class="yyzIndex == '2' ? 'active' : ''"
              @click="changYyzValue('2')"
              >否</span
            >
          </div>
        </div>
        <!-- <div class="filtrate-list">
          <span class="filtrate-title">等级：</span>
          <span class="filtrate-option active">不限</span>
          <span class="filtrate-option">数控机床</span>
          <span class="filtrate-option">氩弧焊稀有气体</span>
        </div> -->
        <!-- <div class="more">更多</div> -->
      </div>
      <!-- <ul class="sorting">
        <li class="drop-down-box charge">
          <div class="drop-down-text">收费情况</div>
          <ul class="drop-down-list">
            <li class="drop-down-option active">收费情况</li>
            <li class="drop-down-option">去登录</li>
            <li class="drop-down-option">付费课</li>
            <li class="drop-down-option">VIP专属课</li>
          </ul>
        </li>
        <li class="drop-down-box type">
          <div class="drop-down-text">课程类型</div>
          <ul class="drop-down-list">
            <li class="drop-down-option active">收费情况</li>
            <li class="drop-down-option">去登录</li>
            <li class="drop-down-option">付费课</li>
            <li class="drop-down-option">VIP专属课</li>
          </ul>
        </li>
        <li class="date has-arrow asc">上架时间</li>
        <li class="play has-arrow">播放量</li>
        <li class="pay has-arrow">购买量</li>
        <li class="result-sum">
          <span class="sum-num">共110个结果</span>
          <span class="turn pre"></span>
          <span class="turn next"></span>
        </li>
      </ul> -->
    </div>
    <div class="filtrate" v-if="pageType == 2">
      <div class="filtrate-content">
        <div class="filtrate-list clearfix">
          <span class="filtrate-title">等级：</span>
          <span
            class="filtrate-option"
            :class="category_id == 0 ? 'active' : ''"
            @click="getSubCategoryList(0)"
            >不限</span
          >
          <span
            class="filtrate-option"
            v-for="item in category"
            :key="item.id"
            :class="category_id == item.id ? 'active' : ''"
            @click="getSubCategoryList(item.id)"
            >{{ item.name }}</span
          >
        </div>
      </div>
    </div>
    <div class="result-box">
      <ul class="result-list">
        <router-link
          :to="
            pageType == 1
              ? '/recordedInfo/' + item.id
              : '/seriesLessonsInfo/' + item.id
          "
          class="result-info"
          v-for="item in list.data"
          :key="item.id"
        >
          <img class="result-img" v-lazy="item.image" :key="item.image" />
          <div class="result-content">
            <div class="result-title">{{ item.name }}</div>
            <!-- <router-link :to="'/recordedInfo/'+item.id" class="result-title">{{item.name}}</router-link> -->
            <div class="result-marks" v-if="item.course_tags">
              <div class="mark" v-for="mark in item.course_tags" :key="mark">
                {{ mark }}
              </div>
            </div>
            <div class="result-lecturers">{{ item.duration }}课时</div>
            <div class="result-describe">{{ item.intro }}</div>
          </div>
          <div class="result-pice-paynum">
            <div class="result-pice"  v-if="websiteData&&websiteData.is_course_pay&&websiteData.is_course_pay==1">
              <span v-if="item.price != '0.00'">￥</span
              >{{ item.price == "0.00" ? "免费" : item.price }}
            </div>
            <!-- <div class="result-paynum">34564人已购</div> -->
          </div>
        </router-link>
      </ul>
     <template v-if="num>0">
       <pagination
        :num="num"
        :limit="limit"
        @getNew="getNew"
        :key="categorySelect.id+'_'+category_id"
        v-if="paginationUpdata"
      ></pagination>
     </template>
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pc/pagination.vue";
export default {
  name: "CourseSelection",
  data() {
    return {
      paginationUpdata: true, //重新渲染分页
      pageType: 0, //1.选课中心 2.系列课
      category: [], //筛选条件
      categorySelect: {}, //选中条件
      category_id: 0, //分类id
      list: [], //课程
      num: 0, //数据总个数
      limit: 10, //一页显示几个
      nowPage: 1, //当前页码
      websiteData:'',
      yyzIndex:'1',
    };
  },
  components: {
    pagination,
  },
  mounted() {
    if (this.$route.name == "SeriesLessons") {
      //系列课
      this.pageType = 2;
      this.getSubCategory();
    } else if (this.$route.name == "CourseSelection") {
      //选课中心
      this.pageType = 1;
      this.getCategory(); //获取分类
    }

    this.websiteData = JSON.parse(localStorage.getItem('websiteState'))
  
  },
  methods: {
    //获取分类-选课中心
    getCategory() {
      this.$axios.post(`/v1/course/category`).then((res) => {
        this.category = res.data;
        let pid = this.$route.query.pid
        let id = this.$route.query.id || 0;
        
        if(pid){
          for(let i in res.data){
            if(res.data[i].id == pid){
              this.categorySelect = res.data[i];
            }
          }
        }else{
          this.categorySelect = 0;
        }
      this.nowPage=1

        this.categoryListBtn(id); //点击二级分类
      });
    },
    //获取分类-系列课
    getSubCategory() {
      this.$axios.post(`/v1/subject/category`).then((res) => {
        this.category = res.data;
        this.getSubCategoryList(0); //获取二级分类-系列课
      });
    },
    //分页
    getNew(nowPage) {
      this.nowPage = nowPage;

      if (this.pageType == 1) {
        this.getCategoryList();
      } else {
        this.getSubCategoryList(this.category_id, "fanye"); //获取二级分类-系列课
      }
    },
    //点击二级分类
    categoryListBtn(id) {
      this.category_id = id;
      this.nowPage=1
       this.getNew(this.nowPage)
      // this.getCategoryList(); //获取课程列表
    },

    //获取课程列表
    getCategoryList() {
      if(this.yyzIndex==2){
        this.list = [];
        this.num =0;
        return
      }
      let category_id = this.category_id;

      if (category_id == 0) {
        category_id = this.categorySelect.id;
      }

      this.$axios.post(`/v1/course/list`, {
        category_id,
        page: this.nowPage,
        pre_page: this.limit,
      }).then((res) => {
        this.list = res.data;
        this.num = res.data.total;
      });
    },
    changYyzValue(num){
      this.yyzIndex=num
      this.getCategoryList()
    },

    //获取二级分类-系列课
    getSubCategoryList(id, type) {
      this.category_id = id;
      let page = 0;

      if (type == "fanye") {
        page = this.nowPage;
      } else {
        page = 1;
        this.nowPage = 1;
        this.paginationUpdata = false;
      }

      this.$axios.post(`/v1/subject/list`, {
        category_id: id,
        page,
        pre_page: this.limit,
      }).then((res) => {
        if (!this.paginationUpdata) {
          this.paginationUpdata = true;
        }
        this.list = res;
        this.num = res.total;
      });
    },
    //切换一级分类
    switchOne(item) {
      this.categorySelect = item;
      this.category_id = 0;
      this.nowPage=1

      this.categoryListBtn(0);  //点击二级分类
    },
  },
  watch: {
    //监听路由判断换题
    $route() {
      this.$router.go(0);
    },
  },
};
</script>
<style scoped lang="scss">
.courseSelection {
  background-color: #f7f7f7;
}
.filtrate {
  width: 100%;
  background-color: #fff;
  margin-bottom: 24px;
  img{
    width: 20px;
  }
}
.sorting {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  .drop-down-box {
    margin-right: 50px;
  }
  .has-arrow {
    font-size: 14px;
    color: #999;
    margin-right: 50px;
    min-height: 18px;
    padding-right: 12px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    &::after {
      content: "";
      display: block;
      width: 8px;
      height: 18px;
      background: url(~@/assets/arrows-lang-ico.png) no-repeat right center;
      background-size: auto 18px;
      position: absolute;
      right: 0;
      top: 0;
    }
    &.asc::after {
      transform: rotate(180deg);
    }
  }
  .result-sum {
    margin-left: auto;
    display: flex;
    align-items: center;
    .sum-num {
      font-size: 14px;
      color: #666;
    }
    .turn {
      width: 24px;
      height: 24px;
      background: url(~@/assets/arrows-right.png) no-repeat right center;
      background-size: 100%;
      cursor: pointer;
      &.pre {
        transform: rotate(180deg);
        margin: 0 4px;
      }
    }
  }
}
.result-box {
  padding-bottom: 88px;
}
.result-list {
  box-sizing: border-box;
  width: 1200px;
  min-height: 700px;
  padding: 24px;
  margin: 0 auto;
  background-color: #fff;
}
.result-info {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  &:hover {
    box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
    border-radius: 8px;
  }
}
.result-img {
  width: 282px;
  height: 156px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}
.result-content {
  width: 578px;
  height: 156px;
  margin-left: 28px;
  position: relative;
}
.result-title {
  display: block;
  font-size: 16px;
  font-weight: 800;
  color: #333;
  text-shadow: 0px 0px 14px rgba(0, 12, 40, 0.14);
  margin-bottom: 10px;
  padding-top: 10px;
}
.result-marks {
  overflow: hidden;
  margin-bottom: 10px;
  .mark {
    padding: 0 8px;
    height: 18px;
    line-height: 18px;
    background: rgba(37, 78, 212, 0.14);
    box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
    border-radius: 10px 2px 10px 2px;
    font-size: 12px;
    color: #254ed4;
    text-align: center;
    float: left;
    margin-right: 10px;
  }
}
.result-lecturers {
  font-size: 14px;
  color: #666;
  text-shadow: 0px 0px 14px rgba(0, 12, 40, 0.14);
  margin-bottom: 23px;
}
.result-describe {
  max-height: 48px;
  font-size: 14px;
  color: #999;
  line-height: 24px;
  text-shadow: 0px 0px 14px rgba(0, 12, 40, 0.14);
  //多行省略号
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: 10px;
}
.result-pice-paynum {
  margin-left: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.result-pice {
  width: 100%;
  font-size: 22px;
  font-weight: 900;
  color: #cd1b14;
  text-shadow: 0px 0px 14px rgba(0, 12, 40, 0.14);
  margin-bottom: 5px;
}
.result-paynum {
  width: 100%;
  font-size: 12px;
  font-weight: 900;
  color: #999;
  text-shadow: 0px 0px 14px rgba(0, 12, 40, 0.14);
  text-align: center;
}
.page-wrap {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  padding-bottom: 20px;
  ::v-deep .pointer.hover {
    background: none;
  }
}
.yyzBox{
  align-items: flex-end;
  span{
    margin-bottom: 0 !important;
  }
}
</style>